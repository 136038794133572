import { requireAuth } from "@/utils/middleware";
const Products = () => import("../views/Products");
const Create = () => import("../views/Create");

export default [
  {
    path: "/products",
    name: "products",
    component: Products,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Products", permission: "owner" },
  },
  {
    path: "/products/create",
    name: "createproducts",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Create Products", permission: "owner" },
  },
  {
    path: "/products/:nano_id/edit",
    name: "editproducts",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Edit Products", permission: "owner" },
  },

  // { path: "create", name: "create-user" }
];
