export default {
  inventoriesLoaded: (state, { inventory }) => {
    state.inventories = JSON.parse(JSON.stringify(inventory));
  },
  inventoryLoaded: (state, { inventory }) => {
    state.inventory = { ...inventory };
  },
  inventoryDeleted: (state, payload) => {
    state.inventories.splice(
      state.inventories.findIndex((u) => u.id === payload),
      1
    );
  },
};
