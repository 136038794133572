/* eslint-disable */
import Cookies from "js-cookie";
import { HttpClient } from "@/utils/http-client.js";
import router from "@/router";
import { errorHandler } from "@/utils/error-handler";

export default {
  async attemptLogin({ commit, dispatch }, { email, password, redirect }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/login", { email, password });

      if (data.success) {
        const { access_token } = data;
        Cookies.set("access_token", access_token, {
          // domain: "circle-esports.co.in",
          // secure: true,
          // sameSite: "Strict",
        }); //set expires and https flag in prod
        commit("authenticated", { access_token });
        dispatch("fetchAuthUser");
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        dispatch(
          "status/setAppStatus",
          {
            status: "Success",
            message: "login success, you will be redirected soon.",
          },
          { root: true }
        );
        setTimeout(() => {
          router.push("/customers");
        }, 1000);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async fetchAuthUser({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/me");
      if (data.success) {
        commit("authenticated", {
          user: data.user,
          authenticated: true,
        });
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async attemptLogout({ commit }) {
    try {
      const { data } = await HttpClient.post("/logout");
      if (data.success) {
        Cookies.remove("access_token");

        commit("clearAuthenticated", {
          access_token: null,
          user: null,
          authenticated: false,
        });
      }
      router.push({ name: "login" });
    } catch (error) {
      errorHandler(error, dispatch);
    }
  },
  async attemptRegister(
    { commit, dispatch },
    { first_name, last_name, user_type, email, password, password_confirmation }
  ) {
    try {
      const { data } = await HttpClient.post("/register", {
        first_name,
        last_name,
        user_type,
        email,
        password,
        password_confirmation,
      });
      if (data.success) {
        commit(
          "status/setAppStatus",
          {
            status: "Success",
            message: data.message,
          },
          { root: true }
        );
        return;
      }
    } catch (error) {
      errorHandler(error, dispatch);
    }
  },
  async attemptResetPassword({ commit, dispatch }, { email }) {
    try {
      const { data } = await HttpClient.post("/forgot-password", { email });
      if (data.success) {
        dispatch(
          "status/setAppStatus",
          {
            status: "Success",
            message: data.message,
          },
          { root: true }
        );
        return;
      }
    } catch (error) {
      errorHandler(error, dispatch);
    }
  },
  openSideNav({ commit, dispatch }) {
    commit("openSideNav");
  },
  closeSideNav({ commit, dispatch }) {
    commit("closeSideNav");
  },
  toggleTheme({ commit }) {
    let theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "dark");
      commit("toggleTheme", "light");
    } else if (theme === "dark") {
      localStorage.setItem("theme", "light");
      commit("toggleTheme", "light");
    } else if (theme === "light") {
      localStorage.setItem("theme", "dark");
      commit("toggleTheme", "dark");
    }
  },
};
