export default {
  usersLoaded: (state, { users, pagination }) => {
    Object.assign(state, users);
    Object.assign(state, pagination);
  },
  userLoaded: (state, { user }) => {
    state.user = { ...user };
  },
  userDeleted: (state, payload) => {
    state.users.splice(
      state.users.findIndex((u) => u.nano_id === payload),
      1
    );
  },
};
