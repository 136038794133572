import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
import { successHandler } from "@/utils/success-handler";
import router from "@/router";

export default {
  async getCustomers(
    { commit, dispatch },
    { page = null, search = null, sortbypending = null }
  ) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(
        `/customers?page=${page ? page : ""}&search=${
          search ? search : ""
        }&sortbypending=${sortbypending ? sortbypending : ""}`
      );

      if (data.success) {
        if (sortbypending) {
          commit("pendingCustomersLoaded", data);
        } else {
          commit("customersLoaded", data);
        }
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async loadCustomer({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(`/customers/${nano_id}`);

      if (data.success) {
        commit("customerLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async storeCustomer({ dispatch }, { customer }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/customers", { ...customer });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async updateCustomer({ commit, dispatch }, { customer, nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.patch(`/customers/${nano_id}`, {
        ...customer,
      });

      if (data.success) {
        commit("customerUpdated", data);
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async deleteCustomer({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/customers/${nano_id}`);

      if (data.success) {
        commit("customerDeleted", nano_id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler("customer deleted", dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async makePayment({ commit, dispatch }, { nano_id, amount }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post(
        `/customers/${nano_id}/make-payment`,
        {
          amount,
        }
      );

      if (data.success) {
        commit("paymentMade", { customer: data.customer });
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return data;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
      return false;
    }
  },
  async clearPendingPayment({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post(
        `/customers/${nano_id}/clear-pending`
      );

      if (data.success) {
        commit("paymentMade", { customer: data.customer });
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return data;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
      return false;
    }
  },
  async placeOrder({ commit, dispatch }, { customer_id, product_id, paid }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post(`/orders`, {
        customer_id,
        product_id,
        paid: paid ? paid : 0.0,
      });

      if (data.success) {
        commit("orderPlaced", { customer: data.customer });
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return data;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
      return false;
    }
  },
  async loadOrders(
    { commit, dispatch },
    { customer_id, status = "", page = "" }
  ) {
    try {
      const { data } = await HttpClient.get(
        `/orders/customers/${customer_id}?status=${
          status ? status : ""
        }&page=${page}`
      );

      if (data.success) {
        commit("ordersLoaded", data.orders);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return data;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
      return false;
    }
  },
  clearDetailState({ commit }) {
    commit("clearDetailState");
  },
  async deleteOrder({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/orders/${nano_id}`);

      if (data.success) {
        commit("orderDeleted", nano_id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler(data.message, dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
