export default {
  productsLoaded: (state, { products }) => {
    Object.assign(state, products);
  },
  productLoaded: (state, { product }) => {
    state.product = { ...product };
  },
  productDeleted: (state, payload) => {
    state.products.splice(
      state.products.findIndex((u) => u.nano_id === payload),
      1
    );
  },
};
