import { requireAuth } from "@/utils/middleware";
const Customers = () => import("../views/Customers");
const PendingCustomers = () => import("../views/PendingCustomers");
const Create = () => import("../views/Create");
const Detail = () => import("../views/Detail");

export default [
  {
    path: "/customers",
    name: "customers",
    component: Customers,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Customers" },
  },
  {
    path: "/customers/pending-payments",
    name: "customerspendingpayments",
    component: PendingCustomers,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Customers with pending payments" },
  },
  {
    path: "/customers/create",
    name: "createcustomers",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Create Customer" },
  },
  {
    path: "/customers/:nano_id/edit",
    name: "editcustomers",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Edit Customer" },
  },
  {
    path: "/customers/:nano_id/details",
    name: "customerdetail",
    component: Detail,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Customer Detail" },
  },

  // { path: "create", name: "create-user" }
];
