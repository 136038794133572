import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";

export default {
  async getEntries({ commit, dispatch }, { cafe, page = "" }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(`/entries?page=${page}`, {
        params: { cafe, page },
      });

      if (data.success) {
        commit("entriesLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
