export default {
  customersLoaded: (state, { customers, pagination }) => {
    Object.assign(state, customers);
    Object.assign(state, pagination);
  },
  pendingCustomersLoaded: (state, { customers, pagination }) => {
    Object.assign(state.pending, customers);
    Object.assign(state.pending, pagination);
  },
  customerLoaded: (state, { customer }) => {
    state.customer = { ...customer };
  },
  customerUpdated: (state, { customer }) => {
    state.customers.splice(
      state.customers.findIndex((u) => u.nano_id === customer.nano_id),
      1,
      { ...customer }
    );
  },
  customerDeleted: (state, payload) => {
    state.customers.splice(
      state.customers.findIndex((u) => u.nano_id === payload),
      1
    );
  },
  paymentMade: (state, { customer }) => {
    state.customers.splice(
      state.customers.findIndex((u) => u.nano_id === customer.nano_id),
      1,
      { ...customer }
    );
  },
  orderPlaced: (state, { customer }) => {
    state.customers.splice(
      state.customers.findIndex((u) => u.nano_id === customer.nano_id),
      1,
      { ...customer }
    );
  },
  ordersLoaded: (state, { orders, pagination }) => {
    state.orders = {
      orders: JSON.parse(JSON.stringify(orders)),
      pagination: JSON.parse(JSON.stringify(pagination)),
    };
  },
  clearDetailState: (state) => {
    state.orders = { orders: [], pagination: {} };
    state.customer = {};
  },
  clearCustomers: (state) => {
    Object.assign(state.customers, []);
    Object.assign(state.pagination, {});
  },
  orderDeleted: (state, payload) => {
    state.orders.orders.splice(
      state.orders.orders.findIndex((u) => u.nano_id === payload),
      1
    );
  },
};
