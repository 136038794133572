import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import Router from "vue-router";
import router from "@/router/index";
import store from "@/store";
import DefaultLayout from "@/layouts/default";
import SidebarLayout from "@/layouts/withSidebar";

Vue.use(Router);
Vue.component("default-layout", DefaultLayout);
Vue.component("sidebar-layout", SidebarLayout);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
