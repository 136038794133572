<template>
  <transition>
    <div class="fixed right-0 mt-24 px-10 pt-5 mb-10 z-100">
      <div :class="status.status ? '' : 'hidden'">
        <div
          class="p-5 items-center leading-none rounded-xl flex bg-neutral-light shadow-xl border border-gray-300"
          :class="
            status.status === 'Success' ? 'text-green-100' : 'text-red-100'
          "
          role="alert"
        >
          <span
            class="flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3 text-white"
            :class="status.status === 'Success' ? 'bg-green-700' : 'bg-red-700'"
            >{{ status.status }}</span
          >
          <span
            class="font-semibold mr-2 text-left flex-auto"
            :class="
              status.status === 'Success' ? 'text-green-700' : 'text-red-700'
            "
            >{{ status.message }}</span
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({ status: ["status/getAppStatus"] }),
  },
  methods: {
    clearStatus() {
      return this.$store.dispatch("status/clearAppStatus");
    },
  },
};
</script>

<style></style>
