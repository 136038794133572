import Router from "vue-router";
import AuthRoutes from "@/modules/auth/router";
import UserRoutes from "@/modules/user/router";
import CafeRoutes from "@/modules/cafe/router";
import CustomerRoutes from "@/modules/customer/router";
import CategoryRoutes from "@/modules/category/router";
import ProductRoutes from "@/modules/product/router";
import InventoryRoutes from "@/modules/inventory/router";
import EntryRoutes from "@/modules/entry/router";
import DashboardRoutes from "@/modules/dashboard/router";
import WaitListRoutes from "@/modules/waitlist/router";

const baseRoutes = [];
const routes = baseRoutes.concat(
  AuthRoutes,
  UserRoutes,
  CafeRoutes,
  CustomerRoutes,
  CategoryRoutes,
  ProductRoutes,
  InventoryRoutes,
  EntryRoutes,
  DashboardRoutes,
  WaitListRoutes
);

export default new Router({
  routes,
  mode: "history",
});
