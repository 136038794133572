export default {
  waitlistLoaded: (state, { waitlist }) => {
    state.waitlist = [...waitlist];
  },

  waitlistDeleted: (state, payload) => {
    state.waitlist.splice(
      state.waitlist.findIndex((u) => u.nano_id === payload),
      1
    );
  },
};
