import { requireAuth } from "@/utils/middleware";
const Entries = () => import("../views/Entries");

export default [
  {
    path: "/entries",
    name: "entries",
    component: Entries,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Payments log" },
  },

  // { path: "create", name: "create-user" }
];
