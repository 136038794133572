import { requireAuth } from "@/utils/middleware";
const Dashboard = () => import("../views/Dashboard");

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Dashboard", permission: "owner" },
  },

  // { path: "create", name: "create-user" }
];
