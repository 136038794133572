import { requireAuth } from "@/utils/middleware";
const Users = () => import("../views/Users");
const Create = () => import("../views/Create");

export default [
  {
    path: "/users",
    name: "users",
    component: Users,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Users", permission: "owner" },
  },
  {
    path: "/users/create",
    name: "createusers",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Create Users", permission: "owner" },
  },
  {
    path: "/users/:nano_id/edit",
    name: "editusers",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Edit Users", permission: "owner" },
  },

  // { path: "create", name: "create-user" }
];
