const Login = () => import("../views/login");

import { noAuthStrict } from "@/utils/middleware";
export default [
  {
    path: "/",
    name: "login",
    component: Login,
    beforeEnter: noAuthStrict,
  },
];
