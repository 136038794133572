import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
import { successHandler } from "@/utils/success-handler";
import router from "@/router";

export default {
  async getInventory({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/inventory");

      if (data.success) {
        commit("inventoriesLoaded", data.inventory);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async loadInventory({ commit, dispatch }, { id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(`/inventory/${id}`);

      if (data.success) {
        commit("inventoryLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async storeInventory({ dispatch }, { inventory }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/inventory", { ...inventory });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async updateInventory({ dispatch }, { inventory, id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.patch(`/inventory/${id}`, {
        ...inventory,
      });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async deleteInventory({ commit, dispatch }, { id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/inventory/${id}`);

      if (data.success) {
        commit("inventoryDeleted", id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler("Inventory deleted", dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
