export default {
  setAppLoading({ commit }, { loading }) {
    commit("setAppLoading", { loading });
  },
  setAppErrors({ commit }, { errors }) {
    commit("setAppErrors", { errors });
  },
  setAppStatus(
    { commit, dispatch, state },
    { status, message, errors, timeOut = 2000 }
  ) {
    commit("setAppStatus", { status, message, errors });
    if (status !== "Error") {
      setTimeout(() => {
        dispatch("clearAppStatus");
      }, timeOut);
    } else {
      setTimeout(() => {
        commit("setAppStatus", {
          status: null,
          message: null,
          errors: state.errors,
        });
      }, 2000);
    }
  },
  clearErrors({ commit }, { errors }) {
    commit("setAppStatus", { errors });
  },
  clearAppStatus({ commit }) {
    commit("setAppStatus", {
      status: null,
      message: null,
      errors: null,
    });
  },
};
