<template>
  <div class="flex-1 flex flex-col">
    <div
      v-if="loading"
      class="absolute dark:bg-gray-300 bg-gray-800 text-gray-700 dark:text-gray-600 opacity-40 inset-0 flex items-center justify-center z-50"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="h-10 w-10 animate-spin"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({ loading: ["status/getLoading"] }),
  },
};
</script>

<style></style>
