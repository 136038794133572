import { requireAuth } from "@/utils/middleware";
const Categories = () => import("../views/Categories");
const Create = () => import("../views/Create");

export default [
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Categories", permission: "owner" },
  },
  {
    path: "/categories/create",
    name: "createcategories",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Create Categories", permission: "owner" },
  },
  {
    path: "/categories/:nano_id/edit",
    name: "editcategories",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Edit Categories", permission: "owner" },
  },

  // { path: "create", name: "create-user" }
];
