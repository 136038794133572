export default {
  setAppStatus: (state, payload) => {
    Object.assign(state, payload);
  },
  setAppErrors: (state, payload) => {
    Object.assign(state, payload);
  },
  setAppLoading: (state, payload) => {
    Object.assign(state, payload);
  },
};
