import store from "@/store";
import isNil from "ramda/es/isNil";

export function requireAuth(to, from, next) {
  const accessToken = store.getters["auth/getAccessToken"];
  const authUser = store.getters["auth/getAuthUser"];
  function proceed() {
    if (!isNil(store.getters["auth/getAuthUser"])) {
      if (to.meta.permission) {
        switch (to.meta.permission) {
          case "owner":
            if (store.getters["auth/getAuthUser"].level > 10) {
              next();
            } else {
              next("/customers");
            }
            break;
          case "staff":
            if (store.getters["auth/getAuthUser"].level < 10) {
              next();
            } else {
              next("/customers");
            }
            break;

          default:
            next("/customers");
            break;
        }
      } else {
        next();
      }
    }
  }
  if (isNil(store.getters["auth/getAccessToken"])) {
    next("/");
  }
  if (!isNil(accessToken) && isNil(authUser)) {
    store.dispatch("auth/fetchAuthUser");
    store.watch(function() {
      if (!isNil(store.getters["auth/getAuthUser"])) {
        proceed();
      }
    });
  }
  if (!isNil(accessToken) && !isNil(authUser)) {
    proceed();
  }
}

export function noAuthStrict(to, from, next) {
  if (isNil(store.getters["auth/getAccessToken"])) {
    next();
  }
  if (!isNil(store.getters["auth/getAccessToken"])) {
    next("/dashboard");
  }
}
