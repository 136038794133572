import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    loading: false,
    status: null,
    message: null,
    errors: null
  },
  actions,
  getters,
  mutations
};
