import { requireAuth } from "@/utils/middleware";
const Inventory = () => import("../views/Inventory");
const Create = () => import("../views/Create");

export default [
  {
    path: "/inventory",
    name: "inventory",
    component: Inventory,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Inventory", permission: "owner" },
  },
  {
    path: "/inventory/create",
    name: "createinventory",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Create Inventory", permission: "owner" },
  },
  {
    path: "/inventory/:id/edit",
    name: "editinventory",
    component: Create,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Edit Inventory", permission: "owner" },
  },

  // { path: "create", name: "create-user" }
];
