import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
import { successHandler } from "@/utils/success-handler";

export default {
  async getWaitlist({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/waitlists");

      if (data.success) {
        commit("waitlistLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async addToWaitlist({ dispatch }, { customer_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/waitlists", { customer_id });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },

  async deleteWaitlist({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/waitlists/${nano_id}`);

      if (data.success) {
        commit("waitlistDeleted", nano_id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler("Category deleted", dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
