import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
// import { successHandler } from "@/utils/success-handler";

export default {
  async getCafes({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/cafes");

      if (data.success) {
        commit("cafesLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  //   async deleteUser({ commit, dispatch }, { nano_id }) {
  //     dispatch("status/setAppLoading", { loading: true }, { root: true });
  //     try {
  //       const { data } = await HttpClient.delete(`/users/${nano_id}`);

  //       if (data.success) {
  //         commit("userDeleted", nano_id);
  //         dispatch("status/setAppLoading", { loading: false }, { root: true });
  //         successHandler("User deleted", dispatch);
  //         return;
  //       }
  //     } catch (error) {
  //       dispatch("status/setAppLoading", { loading: false }, { root: true });
  //       errorHandler(error, dispatch);
  //     }
  //   },
};
