import { requireAuth } from "@/utils/middleware";
const Waitlist = () => import("../views/Waitlist");

export default [
  {
    path: "/waitlist",
    name: "waitlist",
    component: Waitlist,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Waiting list" },
  },

  // { path: "create", name: "create-user" }
];
