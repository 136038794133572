import { requireAuth } from "@/utils/middleware";
const Cafes = () => import("../views/Cafes");

export default [
  {
    path: "/cafes",
    name: "cafes",
    component: Cafes,
    beforeEnter: requireAuth,
    meta: { layout: "sidebar", name: "Cafes" },
  },

  // { path: "create", name: "create-user" }
];
