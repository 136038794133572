import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
import { successHandler } from "@/utils/success-handler";
import router from "@/router";

export default {
  async getUsers({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/users");

      if (data.success) {
        commit("usersLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async loadUser({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(`/users/${nano_id}`);

      if (data.success) {
        commit("userLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async storeUser({ dispatch }, { user }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/users", { ...user });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async updateUser({ dispatch }, { user, nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.patch(`/users/${nano_id}`, { ...user });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async deleteUser({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/users/${nano_id}`);

      if (data.success) {
        commit("userDeleted", nano_id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler("User deleted", dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
