import Axios from "axios";
import isNil from "ramda/es/isNil";
import Cookies from "js-cookie";
import store from "../store";

const HttpClient = Axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    Accept: "application/json",
  },
});

function errorResponseHandler(error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        Cookies.remove("access_token");
        store.dispatch("auth/attemptLogout");
        break;

      default:
        break;
    }
  }
  return Promise.reject(error);
}

// apply interceptor on response
HttpClient.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);
HttpClient.interceptors.request.use(
  function(config) {
    const token = Cookies.get("access_token");
    if (!isNil(token)) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

export { HttpClient };
