import Cookies from "js-cookie";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    access_token: Cookies.get("access_token")
      ? Cookies.get("access_token")
      : null,
    authenticated: false,
    user: null,
    nav: {
      sidebar: false,
    },
    theme: localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : localStorage.setItem("theme", "light"),
  },
  actions,
  getters,
  mutations,
};
