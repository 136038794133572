import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";
import { successHandler } from "@/utils/success-handler";
import router from "@/router";

export default {
  async getCategories({ commit, dispatch }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/categories");

      if (data.success) {
        commit("categoriesLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async loadCategory({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get(`/categories/${nano_id}`);

      if (data.success) {
        commit("categoryLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async storeCategory({ dispatch }, { category }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.post("/categories", { ...category });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async updateCategory({ dispatch }, { category, nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.patch(`/categories/${nano_id}`, {
        ...category,
      });

      if (data.success) {
        successHandler(data.message, dispatch);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        router.go(-1);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
  async deleteCategory({ commit, dispatch }, { nano_id }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete(`/categories/${nano_id}`);

      if (data.success) {
        commit("categoryDeleted", nano_id);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        successHandler("Category deleted", dispatch);
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
