<template>
  <div
    :class="[
      'flex h-screen bg-gray-50 dark:bg-gray-900',
      isSidebarOpen ? 'overflow-hidden' : '',
    ]"
  >
    <sidebar-component />
    <div class="flex flex-col flex-1">
      <navbar-component></navbar-component>
      <div
        v-if="loading"
        class="absolute dark:bg-gray-600 bg-gray-600 opacity-40 inset-0 flex items-center justify-center z-40"
      >
        <span class="text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-10 w-10 animate-spin"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </span>
      </div>
      <main class="h-full pb-16 overflow-y-auto">
        <div class="mx-5 md:mx-16 grid">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "@/components/common/Sidebar";
import NavbarComponent from "@/components/common/Navbar";
import { mapGetters } from "vuex";
// import { mapGetters } from "vuex";
export default {
  components: {
    SidebarComponent,
    NavbarComponent,
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: ["auth/isSidebarOpen"],
      loading: ["status/getLoading"],
    }),

    // ...mapGetters({ auth: ["auth/getAuthStatus"] }),
  },
};
</script>

<style></style>
