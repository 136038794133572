export default {
  authenticated: (state, payload) => {
    Object.assign(state, payload);
  },
  clearAuthenticated: (state, payload) => {
    Object.assign(state, payload);
  },
  openSideNav: (state) => {
    state.nav.sidebar = true;
  },
  closeSideNav: (state) => {
    state.nav.sidebar = false;
  },
  toggleTheme: (state, payload) => {
    state.theme = payload;
  },
};
