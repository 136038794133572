<template>
  <div>
    <!-- <NavBar /> -->
    <status-component></status-component>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import StatusComponent from "@/modules/status/views/status";

const default_layout = "default";
export default {
  name: "app",
  components: {
    StatusComponent,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style></style>
