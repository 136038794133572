export function errorHandler(error, dispatch) {
  if (error.response) {
    dispatch(
      "status/setAppStatus",
      {
        status: "Error",
        message: error.response.data.message,
        errors: error.response.data.errors ? error.response.data.errors : null,
      },
      { root: true }
    );
    return;
  }
  dispatch(
    "status/setAppStatus",
    {
      status: "Error",
      message: "There was a network error, please try again",
    },
    { root: true }
  );
  return;
}
