import { HttpClient } from "@/utils/http-client.js";
import { errorHandler } from "@/utils/error-handler";

export default {
  async loadStats({ commit, dispatch }, { start, end }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.get("/stats", {
        params: { start, end },
      });
      if (data.success) {
        commit("statsLoaded", data);
        dispatch("status/setAppLoading", { loading: false }, { root: true });
        return;
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },

  async deleteStats({ dispatch }, { start, end }) {
    dispatch("status/setAppLoading", { loading: true }, { root: true });
    try {
      const { data } = await HttpClient.delete("/stats", {
        params: { start, end },
      });
      if (data.success) {
        dispatch(
          "status/setAppStatus",
          {
            status: "Success",
            message: `Deleted orders ${data.deleted}`,
          },
          { root: true }
        );
        dispatch("status/setAppLoading", { loading: false }, { root: true });
      }
    } catch (error) {
      dispatch("status/setAppLoading", { loading: false }, { root: true });
      errorHandler(error, dispatch);
    }
  },
};
