import Vue from "vue";
import Vuex from "vuex";
import auth from "@/modules/auth/store";
import status from "@/modules/status/store";
import user from "@/modules/user/store";
import cafe from "@/modules/cafe/store";
import customer from "@/modules/customer/store";
import product from "@/modules/product/store";
import inventory from "@/modules/inventory/store";
import dashboard from "@/modules/dashboard/store";
import entry from "@/modules/entry/store";
import category from "@/modules/category/store";
import waitlist from "@/modules/waitlist/store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV != "production";
export default new Vuex.Store({
  modules: {
    auth,
    status,
    user,
    cafe,
    category,
    customer,
    product,
    dashboard,
    entry,
    waitlist,
    inventory,
  },
  strict: debug,
});
