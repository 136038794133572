export default {
  categoriesLoaded: (state, { categories }) => {
    Object.assign(state, categories);
  },
  categoryLoaded: (state, { category }) => {
    state.category = { ...category };
  },
  categoryDeleted: (state, payload) => {
    state.categories.splice(
      state.categories.findIndex((u) => u.nano_id === payload),
      1
    );
  },
};
