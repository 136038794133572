import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    customer: {},
    customers: [],
    pagination: {},
    orders: {
      orders: [],
      pagination: {},
    },
    pending: {
      customers: [],
      pagination: {},
    },
  },
  actions,
  getters,
  mutations,
};
